import React from "react"
import ReCAPTCHA from "react-google-recaptcha"
import Head from "../components/head"

const siteKey = "6LdKxdIUAAAAAMR9TnBOmz1F3BNY9OVG70weCp4G"

const ContactPage = () => {
  return (
    <section id="contact" className="contact__container">
      <Head />
      <div className="contact__form--card">
        <h1 className="contact__title">Contact Me!</h1>

        <form
          name="contact"
          method="POST"
          action="/success"
          data-netlify-recaptcha="true"
          data-netlify="true"
        >
          <input type="hidden" name="form-name" value="contact" className />
          <div className="contact__form--name">
            <div className="contact__form first--name">
              <label>
                <input
                  type="text"
                  name="firstName"
                  placeholder="First Name"
                  required
                />
              </label>
            </div>
            <div className="contact__form last--name">
              <input
                type="text"
                name="lastName"
                placeholder="Last Name"
                required
              />
            </div>
          </div>

          <div className="contact__form--info">
            <div className="contact__form email">
              <label>
                <input type="email" name="email" placeholder="Email" required />
              </label>
            </div>
            <div className="contact__form phone">
              <label>
                <input type="phone" name="phone" placeholder="Phone" />
              </label>
            </div>
          </div>

          <div className="contact__subject--box">
            <label>
              <input
                name="subject"
                placeholder="Subject"
                required
                className="contact__subject"
              ></input>
            </label>
          </div>

          <div className="contact__message--box">
            <label>
              <textarea
                name="message"
                placeholder="Message"
                required
                className="contact__textarea"
              ></textarea>
            </label>
          </div>

          <div className="form__bottom">
            <ReCAPTCHA theme="dark" required sitekey={siteKey} />

            <p>
              <button className="connectInTouch btn--send" type="submit">
                Send
              </button>
            </p>
          </div>
        </form>
      </div>
    </section>
  )
}

export default ContactPage
